import React from "react";
import Modal from "@mui/material/Modal";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKButton from "../../../components/MKButton";
import Checkbox from "@mui/material/Checkbox";
import InfoIcon from "@mui/icons-material/Info";

const PrivacyConsent = ({ open, onClose, onAgree }) => {
  const [isChecked, setIsChecked] = React.useState(false);

  const handleChange = () => {
    setIsChecked((prev) => !prev);
  };

  const handleAgree = () => {
    if (isChecked) {
      onAgree();
      setIsChecked(false);
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="privacy-consent-title"
      aria-describedby="privacy-consent-description"
    >
      <MKBox
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 500 },
          bgcolor: "background.paper",
          borderRadius: 3,
          boxShadow: 24,
          overflow: "hidden",
          border: "1px solid #ccc",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <MKBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={3}
          py={2}
          bgcolor="#003B2D"
          color="white"
          borderBottom="1px solid rgba(0, 0, 0, 0.1)"
        >
          <MKTypography
            id="privacy-consent-title"
            variant="h6"
            fontWeight="bold"
            display="flex"
            alignItems="center"
          >
            <InfoIcon sx={{ mr: 1 }} /> Ochrana osobních údajů
          </MKTypography>
          <MKButton
            onClick={onClose}
            sx={{
              minWidth: 0,
              p: 0,
              color: "white",
              fontSize: "1.2rem",
              fontWeight: "bold",
              transition: "color 0.2s ease",
              "&:hover": {
                color: "#FFCDD2",
              },
            }}
            aria-label="Close privacy consent modal"
          >
            ✕
          </MKButton>
        </MKBox>

        <MKBox px={4} py={3} id="privacy-consent-description">
          <MKTypography
            variant="body1"
            sx={{
              fontSize: "1.1rem",
              lineHeight: "1.6",
              mb: 3,
            }}
          >
            Vaše osobní údaje budou použity za účelem, abychom vás mohli kontaktovat a zodpovědět váš dotaz. Více informací o ochraně osobních údajů naleznete na stránce{" "}
            <a
              href="https://www.remax-lekvi.cz/informace-o-zpracovani-osobnich-udaju/"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#3f51b5",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              Zásady ochrany osobních údajů
            </a>
            .
          </MKTypography>

          <MKBox display="flex" alignItems="center" mb={3}>
            <Checkbox
              checked={isChecked}
              onChange={handleChange}
              inputProps={{ "aria-label": "Consent checkbox" }}
            />
            <MKTypography
              variant="body2"
              ml={1}
              sx={{
                fontSize: "1rem",
              }}
            >
              Souhlasím se zpracováním osobních údajů.
            </MKTypography>
          </MKBox>

          <MKBox display="flex" justifyContent="center" gap={2}>
            <MKButton
              onClick={handleAgree}
              color="success"
              disabled={!isChecked}
              variant="contained"
              sx={{
                borderRadius: "8px",
                fontSize: "1rem",
                textTransform: "none",
                fontWeight: "bold",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#45A049",
                },
              }}
            >
              Potvrdit
            </MKButton>
            <MKButton
              onClick={onClose}
              color="error"
              variant="outlined"
              sx={{
                borderRadius: "8px",
                fontSize: "1rem",
                textTransform: "none",
                fontWeight: "bold",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "#FFEBEE",
                },
              }}
            >
              Zrušit
            </MKButton>
          </MKBox>
        </MKBox>
      </MKBox>
    </Modal>
  );
};

export default PrivacyConsent;
